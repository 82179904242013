<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex" />

    <b-navbar-nav class="nav align-items-center justify-content-between w-100">
      <div class="d-flex cursor-pointer" @click="$router.push('/accurate-setting')">
        <button class="btn rounded-circle mr-1" :class="isConnectedAccurate ? 'btn-success' : 'btn-danger'" style="padding: 10px"></button>
        <p class="mb-0 align-self-center">Accurate: <b>{{ isConnectedAccurate ? 'Connected' : 'Disconnected '}}</b></p>
      </div>
      <div class="d-flex">
        <notification-dropdown />
        <b-nav-item-dropdown
          v-if="user.profile"
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
          class="dropdown-user"
        >
          <template #button-content>
            <div class="d-sm-flex d-none user-nav">
              <p
                class="user-name font-weight-bolder mb-0"
              >
                {{ user.profile.fullName }}
              </p>
              <!-- <span class="user-status">Admin</span> -->
            </div>
            <b-avatar
              size="40"
              variant="light-primary"
              badge
              :src="getUrlOfFile(user.profile.photo)"
              class="badge-minimal"
              badge-variant="success"
            />
          </template>

          <b-dropdown-item link-class="d-flex align-items-center">
            <router-link
              class="w-100"
              to="/profile"
              style="text-decoration: none; color: unset"
            >
              <feather-icon
                size="16"
                icon="UserIcon"
                class="mr-50"
              />
              <span>Profile</span>
            </router-link>
          </b-dropdown-item>

          <b-dropdown-item link-class="d-flex align-items-center">
            <router-link
              class="w-100"
              to="/edit-password"
              style="text-decoration: none; color: unset"
            >
              <feather-icon
                size="16"
                icon="ToolIcon"
                class="mr-50"
              />
              <span>Edit Password</span>
            </router-link>
          </b-dropdown-item>

          <b-dropdown-divider />

          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="logout"
          >
            <feather-icon
              size="16"
              icon="LogOutIcon"
              class="mr-50"
            />
            <span>Logout</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </div>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { getUrlOfFile } from '@/utils/helpers'
import NotificationDropdown from './NotificationDropdown.vue'

export default {
  components: {
    NotificationDropdown,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      getUrlOfFile,
      isConnectedAccurate: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.user.me
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('user/logout')
    },
    checkStatus() {
      this.$axios.get('/accurate-setting/status')
        .then(res => {
          this.isConnectedAccurate = res.data.data
        })
        .catch(error => 
          this.$refs.response.setResponse(error.response.data, 'danger')
        )
    }
  },
  mounted() {
    this.checkStatus()
  },
  watch: {
    '$route.path': function() {
      this.checkStatus()
    }
  }
}
</script>
