<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25 align-self-center"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="unread"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          v-if="unread"
          pill
          variant="light-primary"
        >
          {{ unread }} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-if="config.total_data"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
      @scroll="hideIcon"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification, index in config.rows"
        :key="index"
        @click="readNotification(notification.id)"
      >
        <b-media :class="{'bg-primary-opacity-25': !notification.readAt}">
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.data.title }}
            </span>
          </p>
          <small class="notification-text">
            {{ notification.data.body }}
          </small>
        </b-media>
      </b-link>

    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer position-relative">
      <BIconArrowDownCircle
        variant="primary"
        style="width: 30px; height: 30px; z-index: 99; top: -30%; left: 45%; transform: translate(-50%, -50%);"
        class="animate-bounce icon-animate position-absolute"
        :class="{'d-none': no_more_content}"
      />
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="loadMore"
      >Read more notifications</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BButton, BIconArrowDownCircle,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import api from '@/utils/api'
import Pusher from 'pusher-js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ToastificationContent,
    BIconArrowDownCircle,
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
    }
  },
  data() {
    return {
      config: {
        uri: this.$route.meta.link,
        api: api.notification,
        rows: [],
        orderby: 'createdAt',
        sort: 'desc',
        total_data: 0,
        total_page: 0,
        per_page: 10,
        page: 1,
        unread: 0,
      },
      no_more_content: true,
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    unread() {
      return this.config.unread
    },
  },
  created() {
    this.get()
    this.getUnreadNotification()
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.subscribe()
      }, 1000)
    })
  },
  methods: {
    hideIcon() {
      setTimeout(() => {
        this.no_more_content = true
      }, 200)
    },
    get() {
      this.$notification.get(this.config)
    },
    getUnreadNotification() {
      this.$axios.get('notification/count-unread')
        .then(res => {
          this.config.unread = res.data.data
        })
    },
    loadMore() {
      this.config.page++
      this.$notification.loadMore(this.config, this.config.page)

      this.no_more_content = false
    },
    readNotification(id) {
      const notification = this.config.rows.find(notif => notif.id === id)
      if (notification) {
        const response = this.directTo(notification)
        if (response && !notification.readAt) {
          this.config.unread--
          this.$axios.put(`${this.config.api}/read/${id}`)
        }
        notification.readAt = true
      }
    },
    directTo(notification) {
      const { type, data } = notification
      let url = ''
      switch (type) {
        case 'PO_DETAIL':
        case 'PO_LIST':
          url = 'purchase-order'
          break
        case 'PROMO_LIST':
        case 'PROMO_DETAIL':
          url = 'promo'
          break
        default:
          break
      }

      if (type.includes('DETAIL') && (type !== 'TREATMENT_DETAIL' && type !== 'PRODUCT_DETAIL')) {
        url = `/${url}/${data.itemId}`
      }

      if (url.toString() !== this.$route.path.toString()) {
        if (type === 'TREATMENT_DETAIL' || type === 'PRODUCT_DETAIL') {
          const defaultActive = type === 'PRODUCT_DETAIL' ? 'product' : 'treatment'
          this.$router.push({ name: 'detail-kfs', params: { id: data.kfsId, defaultActive } })
          return true
        }
        if (type === 'PROMO_DETAIL' && data.kfsId) {
          this.$router.push({ name: 'detail-kfs', params: { id: data.kfsId, defaultActive: 'promo' } })
          return true
        }

        this.$router.push(url)
        return true
      }

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'You\'re in the right page',
          icon: 'InfoIcon',
          variant: 'primary',
        },
      })
      return false
    },
    subscribe() {
      const pusher = new Pusher(process.env.VUE_APP_KEY_PUSHER, {
        cluster: 'ap1',
      })
      pusher.subscribe(`notification-kezia-${this.user?.me.id}`)
      this.binding(pusher)
    },
    binding(pusher) {
      pusher.bind('new-notification', notification => {
        const payload = {
          data: notification,
          readAt: null,
        }
        this.config.rows = [payload, ...this.config.rows]
      })
    },
  },
}
</script>

<style>

</style>
